import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BillingService } from './../../../../../glamera-booking-link/src/app/core/services/billing.service'; 
import { SideDialogService } from '@s-app/common/services';
import { Invoice, BillingItem } from '@bl-src/app/core/models/invoice.model';
import Swal from 'sweetalert2';
import { SharedService } from '@s-app/common/services/shared.service';
import { BranchService } from '@bl-src/app/core/services/branch.service';
 

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  
})
export class InvoicesComponent implements OnInit {
  @Input()
  public branch: any | null = null;
  public branches: any []=[];
  public datachecked=false

  @ViewChild('invioceDetails')
  public invioceDetails!: ElementRef;

  public invoices: Invoice[] = [];
  public originalInvoices: Invoice[] = [];
  public selectedInvoice: Invoice | undefined;
  public selectedBillingItems: number[]=[];
  public selectedPaymentMethod: string = 'Mada';
  public showPaymentPopup: boolean = false;
  public isPrimaryButton: boolean = false;
  public expandedInvoices: { [key: string]: boolean } = {};

  constructor(
    private billingService: BillingService,
    private  branchService: BranchService,
    private sideDialogService: SideDialogService,
    public sharedService: SharedService

  ) {}

  ngOnInit() {
    this.showInvoices();
    this.getAllBranches();
  }

  public showInvoices(criteria?:any): void {
    this.billingService.getBillings(criteria).subscribe(
      (data: { result: Invoice[] }) => {
        this.invoices = data.result;
        this.originalInvoices = data.result ;
        this.datachecked=true;
        
      },
      (error: any) => {
        console.error('Error fetching billings:', error);
      }
    );
  }

  public getAllBranches(): void {
    this.branchService.getAllLiteBranches().subscribe(
      (data:  any ) => {
          this.branches=data;
      },  
      (error: any) => {
        console.error('Error fetching billings:', error);
      }
    );
  }

  public searchFreeText($event:any): void {
    let freeText = $event.target.value;  
      this.invoices = this.originalInvoices.filter((invoice) => 
        invoice.billingItems.some(i=>   i.title.toLowerCase().includes(freeText.toLowerCase()) || i.invoiceNo.toString().toLowerCase().includes(freeText.toLowerCase()) )  
    || invoice.code.toString().toLowerCase().includes(freeText.toLowerCase() 
    )); 
  }
 
  public searchBranchId($event:any): void {
    let branchId = $event.target.value;  
      this.invoices = this.originalInvoices.filter((invoice) => invoice.billingItems.some(i=>i.branchId == branchId)); 
  }
  searchedBranch:string | null;
  public searchBranch(id:any,name:string|null): void {
    let branchId = id;  
    if(branchId==-1){
      this.searchedBranch=null;
      this.invoices = this.originalInvoices;
      return;
    }
    this.searchedBranch=name;
      this.invoices = this.originalInvoices.filter((invoice) => invoice.billingItems.some(i=>i.branchId == branchId)); 
    
  }

  public searchMonths($event:any): void {
    let date = $event.target.value;
    let month = new Date(date).getMonth() + 1;
    let year =  new Date(date).getFullYear();
  
      this.invoices = this.originalInvoices.filter((invoice) => invoice.billingItems.some(i=>i.billingDate 
        && new Date(i.billingDate).getMonth() + 1 == month 
        && new Date(i.billingDate).getFullYear() == year)); 

  }

  public showPayment(invoice: any, billingItems: any, branchId: any, isPrimaryButton: boolean): void {
    this.selectedInvoice = invoice;
   // this.selectedBillingItems = billingItems;
    this.isPrimaryButton = isPrimaryButton;
    this.showPaymentPopup = true;
  }

  selectBillingItem(event:any,billingId:number){
    if(event.target.checked){
      this.selectedBillingItems.push(billingId);

    }else{
      this.selectedBillingItems = this.selectedBillingItems.filter((item)=>item!=billingId);
    }

    
  }

  public submitPaymentMethod(): void {

    console.log('selectedBillingItems:', this.selectedBillingItems);
    if (this.selectedPaymentMethod && this.selectedInvoice   ) {


      const paymentData = 
      this.isPrimaryButton && this.selectedBillingItems.length>0

        ? 
        { 
          BillingIds: this.selectedBillingItems, 
          PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
        }
        :
        
        {
            BillingCode: this.selectedInvoice.code,  
            PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
          }
      ;
        

      this.billingService.postPayment(paymentData).subscribe(
        (response) => {
          console.log('Payment successful:', response);
          if (response.paymentProviderURL) {
            window.location.href = response.paymentProviderURL;
          }
        },
        (error: any) => {
          console.error('Payment error:', error);
        }
      );
      this.showPaymentPopup = false;
    } else {
      console.log('Please select a payment method.');
    }
  }

  public selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }

  public closePaymentPopup(): void {
    this.showPaymentPopup = false;
  }

 

 
  public showInvoice(id: string): void {
    this.selectedInvoice = this.invoices.find((invoice) => invoice.code === id);
    if (!this.selectedInvoice) {
      return;
    }
    Swal.fire({
      html: this.invioceDetails.nativeElement,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml:
        '<i class="fa-solid fa-xmark text-xl text-gray-800"></i>',
      width: 'min(47rem, 100%)',
      customClass: {
        htmlContainer: 'm-0',
        closeButton: 'absolute left-0',
      },
    });
  }

  public toggleInvoiceDetails(invoiceCode: string): void {
    this.expandedInvoices[invoiceCode] = !this.expandedInvoices[invoiceCode];
  }
}
