import { Injectable } from '@angular/core';
import { Branch, PaymentMethod } from '@s-app/common/models';
import { BehaviorSubject, take } from 'rxjs'; 
import { BranchService } from '@bl-src/app/core/services/branch.service';

@Injectable({
  providedIn: 'root',
})
export class BranchCacheService {
  public list$: BehaviorSubject<Branch[]> = new BehaviorSubject<
      Branch[]
  >([]);

  public constructor(    private branchService: BranchService ) {
    this.refresh();
  }

  public refresh(lastId?:number): void {
    console.log("test");
    // this.branchService
    //   .getBranchesSubscriptions()
    //   .pipe(take(1))
    //   .subscribe((response: any) => {
    //     this.list$.next(response.result);
    //   });
  }
}
