import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SideDialogService } from '@s-app/common/services';
import { BranchService } from '@bl-src/app/core/services/branch.service';
import { BillingService } from '@bl-src/app/core/services/billing.service';
import { GlameraSharedService } from 'glamera-shared';
import { Branch } from '@s-app/common/models';
import { CompanySubscription } from '@s-app/common/models/company-subscription.model';

@Component({
  selector: 'app-branch',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']

})
export class BranchesComponent implements OnInit {
  public branches: Branch[] = [];
  public datachecked=false
  public errorMessage: string = '';
  public showPaymentPopup: boolean = false;
  public selectedBillingItem: any;
  public selectedPaymentMethod: string = 'Mada';
  public isPrimaryButton: boolean = false;
  public expandedInvoices: { [key: string]: boolean } = {};
  public selectedInvoice: any | undefined;

  public constructor(
    public translateService: TranslateService,
    private sideDialogService: SideDialogService,
    private branchService: BranchService,
    private billingService: BillingService,
    private sharedService: GlameraSharedService,

  ) { }

  ngOnInit(): void {
    this.branchService.getBranchesSubscriptions().subscribe(
      data => {
        
        this.branches = data.result.map((item: CompanySubscription) => ({

          branchId: item.branch.accountSetupId,
          name: item.branch.providerName,
          billingIds: item.branch.billingIds,
          billingCostToPay: item.branch?.billingCostToPay,
          billingCostCurrency: item.branch?.billingCostCurrency,

          subscription: item.subscription == null || item.subscription?.id == 0 ? null : {
            subscriptionId: item.subscription.id,
            subscriptionPlanId: item.subscription.subscriptionPlanId,
            isTrial: item.subscription.isTrial,
            userCount: item.subscription.planUsersCount,
            planName: item.subscription.subscriptionPlanName,
            leftDays: item.subscription.daysLeft,
            maxUserCount: item.subscription.planUsersCount,
            cycle:item.subscription.cycle,
            ratio: item.subscription.ratio,
            scheduledChange: item.subscription.scheduledChange,
            price: {
              amount: item.subscription.planPrice,
              period: this.getPeriod(item.subscription.cycle) ,
              periodInDays: item.subscription.cycle,
              currency: item.subscription.planCurrency
            },
          },
        }));

        
        this.datachecked=true;
      },
      error => {
        this.errorMessage = error.message;
      }
    );
  }


  openUpgradeTrial(branchId: number,cycle:number ): void { 
    this.sideDialogService.showBuyPlanDialog({side:'center', branchId:branchId , cycle: cycle , isUpgrade:true  }); 
  }
  openUpgradePopup(branchId: number,subscriptionPlanId:number,cycle:number ): void { 
    console.log('openUpgradePopup' );
    this.sideDialogService.showBuyPlanDialog({side:'center', branchId:branchId ,planId:subscriptionPlanId , cycle: cycle , isUpgrade:true ,closeCycle:true}); 
  }
  openSubscribePopup(branchId: number ,subscriptionPlanId? :number,cycle?:number ): void { 
    console.log('subscriptionPlanId',subscriptionPlanId);
    this.sideDialogService.showBuyPlanDialog({side:'center', branchId:branchId ,planId:subscriptionPlanId, cycle: cycle }); 
  }

  public submitPaymentMethod() {
    if (!this.selectedPaymentMethod) {
      console.log('Please select a payment method.');
      return;
    }

    if (this.selectedBillingItem) {

      const paymentData = {
        BillingIds: this.selectedBillingItem,
        PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
      };

      this.billingService.postPayment(paymentData).subscribe(
        (response) => {
          console.log('Payment successful:', response);
          if (response.paymentProviderURL) {
            window.location.href = response.paymentProviderURL;
          }
          this.showPaymentPopup = false;
        },
        (error: any) => {
          console.error('Payment error:', error);
        }
      );
    }
  }





  public showPayDialog(billingCode?: string): void {
    this.sideDialogService.showPayDialog(); // Pass billingCode to dialog if exists
  }

  public closePaymentPopup(): void {
    this.showPaymentPopup = false;
  }
  public selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }

  public showPayment(billingIds: number[]): void {
    this.selectedBillingItem = billingIds;
    this.showPaymentPopup = true;
  }


  public getPeriod(days: number): string {

    if (days === 1) {
      return this.translateService.instant('common.day');
    } else if (days === 7) {
      return this.translateService.instant('common.week');
    } else if (days === 30) {
      return this.translateService.instant('common.month');

    }
    else if (days === 90) {
      return this.translateService.instant('common.quarter');
    }
    else if (days === 180) {
      return this.translateService.instant('common.halfYear');
    }
    else if (days === 360 || days === 365) {
      return this.translateService.instant('common.year');
    } else {
      return this.translateService.instant('common.days', { days });
    }

  }
}
