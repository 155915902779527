<br>
<div *ngIf="branches?.length==0&&datachecked" class="mb-28 text-center">
  <div>
    <i class="fa-solid fa-ban"></i>
  </div>
  <div class="my-3 font-semibold text-3xl text-gray-800">
    
    {{ 'translate_branch_not_found' | translate }}
  </div>
  <div class="mb-14 text-gray-400">
    {{ 'translate_no_banches_found' | translate }}
  </div>
</div>  


<div class="grid gap-4 md:grid-cols-2">
 
  
  <div *ngFor="let branch of branches" class="rounded-2xl bg-white shadow">
    <div class="py-6 px-4 font-semibold border-b border-gray-200 ">
      <a *ngIf="branch.subscription" class="cursor-pointer "
        [routerLink]="['', 'subscription', 'branches', branch.subscription.subscriptionId]">{{ branch.name }}</a>
      <p *ngIf="!branch.subscription">{{ branch.name }}</p>


    </div>
    <div *ngIf="branch.subscription" class="pt-6 px-4">
      <div class="flex">
        <div class="flex-1">
          <div class="font-semibold mb-1">
            {{ branch.subscription.planName }}


          </div>
        
          <div class="text-sm p-color" *ngIf="branch.subscription.isTrial">
            {{'translate_trial_account' | translate}}
          </div>

          <div class="text-sm p-color" *ngIf="branch.subscription.scheduledChange !=null">
            {{branch.subscription.scheduledChange.title}}
          </div>

          <div class="text-gray-400 text-xs" *ngIf="branch.subscription.scheduledChange !=null">
            {{ 'translate_scheduled_change' | translate }} {{branch.subscription.scheduledChange.downgradeOn | date:
            'dd/MM/yyyy'}}
          </div>



        </div>
        <div class="flex mb-6">
          <span class=" text-lg text-gray-600 font-bold">

            {{
            branch.subscription.price.amount | currency : branch.subscription.price.currency
            }}</span>

          <span class="text-lg text-gray-400   font-medium  ">
            /{{ "translate_" + branch.subscription.price.period | translate }}
          </span>
        </div>
      </div>
      <div class="flex align-items-center">
        <div class="flex-1">
          <img *ngFor="let user of [].constructor(branch.subscription.userCount); let i = index"
            src="assets/img/user-avatar.avif" class="inline h-8 w-8 rounded-full border-2 border-white"
            [style.transform]="
              'translateX(calc(12px * ' +
              i +
              ' * ' +
              (translateService.currentLang === 'en' ? -1 : 1) +
              '))'
            " />
        </div>
        <div class="text-gray-400 text-xs font-medium">
          {{ branch.subscription.userCount }}/{{ branch.subscription.maxUserCount }}
          {{ "translate_users" | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="branch.subscription" class="m-4 h-[1px] bg-gray-300">
      <div class="-translate-y-[1px] h-[3px] rounded-full" [style.width]="
            (branch.subscription.ratio)  +'%'
        " [ngClass]=" branch.subscription.leftDays > 0 ? 'bg-cyan-300' : 'bg-red-400'"></div>
    </div>
    <div *ngIf="branch.subscription" class="flex pb-4 px-6">
      <div class="flex-1">
        <ng-container *ngIf="branch.subscription.leftDays > 0">
          <span class="text-gray-400 text-sm font-bold">
            {{ branch.subscription.leftDays }}
          </span>
          <span class="text-gray-400 text-xs ms-2">
            {{ "translate_days_to_restarted" | translate }}
          </span>
        </ng-container>
  
      </div>


      <ng-container *ngIf="branch.subscription?.isTrial">
        <a (click)="openSubscribePopup(branch.branchId,branch.subscription.subscriptionPlanId,branch.subscription.cycle)"
          class="text-primary-700 hover:text-primary-800 text-decoration-none cursor-pointer">
          {{'translate_subscribe_now' | translate}}
          <i class="fa-solid fa-arrow-up ms-1 ltr:rotate-45 rtl:-rotate-45"></i>
        </a>
      </ng-container>

      <ng-container *ngIf="branch.billingIds.length>0 &&!branch.subscription?.isTrial">
        <a (click)="showPayment(branch.billingIds)"
          class="text-primary-700 hover:text-primary-800 cursor-pointer text-decoration-none">
          <span *ngIf="branch.billingCostToPay"> ({{branch.billingCostToPay | currency :
            branch.billingCostCurrency}})</span>
          {{'translate_pay_now' | translate}}
          <i class="fa-solid fa-arrow-up ms-1 ltr:rotate-45 rtl:-rotate-45"></i>
        </a>
      </ng-container>

      <ng-container *ngIf="!branch.subscription?.isTrial && branch.billingIds.length==0">
        <a (click)="openUpgradePopup(branch.branchId,branch.subscription.subscriptionPlanId,branch.subscription.cycle)"
          class="text-primary-700 hover:text-primary-800 cursor-pointer text-decoration-none">
          <span *ngIf="branch.billingCostToPay"> ({{branch.billingCostToPay | currency :
            branch.billingCostCurrency}})</span> {{'translate_upgradenow' | translate}}
          <i class="fa-solid fa-arrow-up ms-1 ltr:rotate-45 rtl:-rotate-45"></i>
        </a>
      </ng-container>
    </div>


    <div *ngIf="branch.subscription ==null" class="flex pb-4 px-6  register-btn">
      <ng-container>
        <a [routerLink]="'/plans/' + branch.branchId"
          class="text-primary-700 hover:text-primary-800 text-decoration-none">
          {{'translate_subscribe_now' | translate}}
          <i class="fa-solid fa-arrow-up ms-1 ltr:rotate-45 rtl:-rotate-45"></i>
        </a>
      </ng-container>
    </div>

  </div>
</div>






<!-- Payment Method Popup -->
<div *ngIf="showPaymentPopup" class="popup">
  <div class="popup-content">
    <h2>{{'translate_select_payment_method'|translate }}</h2>
    <div class="payment-options">
      <button (click)="selectPaymentMethod('Mada')" [ngClass]="{ selected: selectedPaymentMethod === 'Mada' }">
        <img src="../../../assets/mada.png" alt="Mada" class="payment-logo" />
        Mada
      </button>
      <button (click)="selectPaymentMethod('Visa')" [ngClass]="{ selected: selectedPaymentMethod === 'Visa' }">
        <img src="../../../assets/visa.png" alt="Visa" class="payment-logo visa-logo" />
        Visa
      </button>
    </div>
    <div class="popup-actions">
      <button (click)="submitPaymentMethod()" class="submit-btn">Submit</button>
      <button (click)="closePaymentPopup()" class="cancel-btn">Cancel</button>
    </div>
  </div>
</div>