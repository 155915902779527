import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlameraSharedService {

  constructor() {

     
   }
    
   get  token(){
    var item= localStorage.getItem('token') ;
    if(item){
      return item;
    }
    else 
    return null; 
  }
  get  userIdentity(){
    var item= localStorage.getItem('userIdentity') ;
    if(item){
      return JSON.parse(item);
    }
    else 
    return null; 
  }

      
  get  company(){
    var item= localStorage.getItem('company') ;
    if(item){
       return JSON.parse(item);
    }
    else 
    return null; 
  }
  //  getUserData(){
  //     return localStorage.getItem('userIdentity');
  //  }


  
}
